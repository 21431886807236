<template>
  <DefaultLayout>
    <v-layout>
      <v-flex>
        <MyProfile />
        <ChangePassword class="ma-5"/>
      </v-flex>
    </v-layout>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/default.vue";
import MyProfile from "@/components/users/myprofile";
import ChangePassword from "@/components/users/changepassword";

export default {
  name: "myporfile",
  components: {
    DefaultLayout,
    MyProfile,
    ChangePassword,
  },
  data: function() {
    return {
      user: {},
    };
  },
  computed: {
    
  },
  mounted() {
    
  },
  methods: {
    
  },
};
</script>

<style lang="scss"></style>
