<template>
  <div>
    <v-card class="ma-5">
      <v-card-title>{{ $t("myProfile.section.user") }}</v-card-title>
      <v-card-text>
        <UserForm :user="user" ref="userForm" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="saveProfile()">{{ $t("myProfile.form.saveProfile") }}</v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="ma-5">
      <v-card-title>{{ $t("myProfile.section.profile") }}</v-card-title>
      <v-card-text>
        <v-row v-if="showFull">
          <v-col cols="12" md="6">
            <v-switch v-model="profile.showSearchOnHome" :label="$t('myProfile.switches.showSearchOnHome')"></v-switch>
          </v-col>
          <v-col cols="12" md="6">
            <v-switch v-model="profile.keepFiltersOverPages" :label="$t('myProfile.switches.keepFiltersOverPages')"></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-switch v-model="profile.keepFiltersOpen" :label="$t('myProfile.switches.keepFiltersOpen')"></v-switch>
          </v-col>
          <v-col cols="12" md="6">
            <v-switch v-model="profile.hideReleaseNotesPopup" :label="$t('myProfile.switches.showReleaseNotesOnLogin')"></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-select v-model="user.lang" :items="['de', 'en']" :tabIndex="10102" :label="$t('myProfile.labels.language')"></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-switch v-model="profile.useDarkTheme" :label="$t('myProfile.switches.useDarkTheme')"></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="saveProfile()">{{ $t("myProfile.form.saveSettings") }}</v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="ma-5" v-if="false">
      <v-card-title>{{ $t("myProfile.section.mails") }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12">
            Mail Signature:
            <ckeditor ref="editor" :editor="editor" v-model="sig" :config="editorConfig" :tabIndex="10103"></ckeditor>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="saveProfile()">{{ $t("myProfile.form.saveSettings") }}</v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="ma-5" v-if="showFull">
      <v-card-title>{{ $t("myProfile.section.imap") }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="profile.imap.server"
              :label="$t('myProfile.imap.server')"
              required
              :tabIndex="10110"
              @change="imapChanged = true"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="profile.imap.login"
              :label="$t('myProfile.imap.login')"
              required
              :tabIndex="10111"
              @change="imapChanged = true"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="profile.imap.pwd"
              :label="$t('myProfile.imap.pwd')"
              required
              :tabIndex="10112"
              :type="imapPwd ? 'text' : 'password'"
              :append-outer-icon="imapPwd ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              @click:append-outer="imapPwd = !imapPwd"
              @change="imapChanged = true"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-icon v-if="imapStatus == 'Unknow'" class="mr-3">mdi-help-circle</v-icon>
        <v-icon v-else-if="imapStatus == 'ok'" color="green" class="mr-3">mdi-check-circle</v-icon>
        <v-icon v-else color="red" class="mr-3">mdi-alert-circle</v-icon>
        {{ $t("myProfile.imap.statusLabel", { status: imapStatus }) }}
        <v-spacer></v-spacer>
        <v-btn v-if="false" color="secondary" @click="checkImap" :disabled="imapChanged">Check</v-btn>
        <v-btn color="primary" @click="saveProfile()">{{ $t("myProfile.form.saveSettings") }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { setDark } from "@/services/theme";
import { formatMixin, formMixin, richMixin } from "@/helpers/mixins";

import UserForm from "@/components/users/userform";

export default {
  name: "taskmanager",
  mixins: [formatMixin, formMixin, richMixin],
  components: {
    UserForm,
  },
  data() {
    return {
      profile: {
        showSearchOnHome: true,
        keepFiltersOverPages: false,
        mailSignature: "",
        imap: {},
      },
      imapStatus: "Unknow",
      imapChanged: false,
      lang: "de",
      sig: "",
      imapPwd: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    showFull() {
      return this.user.role != "calview";
    },
  },
  watch: {
    "user.lang": function (val) {
      this.$root.$i18n.locale = val;
      this.profile.lang = val;
    },
    "profile.useDarkTheme": function (val) {
      setDark(val);
    },
    sig: function (val) {
      this.profile.mailSignature = val;
    },
  },
  mounted() {
    let p = this.user.profile;
    p.imap = p.imap || {};

    this.profile = p;
    this.sig = this.profile.mailSignature;
    this.imapChanged = false;
    this.checkImap();
  },
  methods: {
    saveProfile: function () {
      let data = this.$refs.userForm.getData();
      data.profile = this.profile;
      data.lang = this.user.lang;

      if (data.profile.imap && !data.profile.imap.pwd) {
        delete data.profile.imap.pwd;
      }

      this.$store
        .dispatch("setUser", data)
        .then(() => {
          this.imapChanged = false;
          this.checkImap();
          this.$store.dispatch("showSnackMessage", { message: "Data saved" });
        })
        .catch((e) => {
          this.$store.dispatch("showSnackMessage", { message: e });
        });
    },
    checkImap: function () {
      axios
        .get(`/api/mails/getimapstatus`)
        .then((res) => {
          this.imapStatus = res.data;
          this.$store.dispatch("showSnackMessage", { message: "Data saved" });
        })
        .catch((e) => {
          this.imapStatus = "Error";
          this.$store.dispatch("showSnackMessage", { message: e });
        });
    },
  },
};
</script>

<style lang="scss"></style>
